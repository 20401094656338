import React, { useEffect, useState } from 'react';

import { BASE_IMG_URL } from 'constants/image';

import { getLicenseInfosSelector } from 'stores/configuration/selectors';
import { useZustandWebConfiguration } from 'stores/configuration/store';

import styles from './AnguanLicense.module.css';

const AnguanLicense = () => {
  const [loaded, setLoaded] = useState(false);

  const licenseInfo = useZustandWebConfiguration(getLicenseInfosSelector);

  const indexOfKeyAndDomain: number = (licenseInfo?.domains || [''])?.indexOf(
    window.location.host
  );

  useEffect(() => {
    if (!licenseInfo || indexOfKeyAndDomain < 0) {
      return;
    }

    const script = document.createElement('script');

    script.src = licenseInfo?.validatorSite.replaceAll(
      '[VALIDATOR_LINK]',
      licenseInfo.validatorLinks[indexOfKeyAndDomain]
    );

    script.async = true;
    script.addEventListener('load', () => setLoaded(true));

    document.body.appendChild(script);
  }, [licenseInfo]);

  useEffect(() => {
    if (!loaded || indexOfKeyAndDomain < 0) {
      return;
    }

    const partOfKey = licenseInfo?.validatorLinks?.[indexOfKeyAndDomain] || '';

    const key = `anj_${partOfKey}`.replaceAll('-', '_');

    // @ts-ignore
    window[key]?.init();
  }, [loaded]);

  const key = licenseInfo?.validatorLinks?.[indexOfKeyAndDomain] || '';

  return (
    <div>
      {indexOfKeyAndDomain >= 0 ? (
        <div
          id={`anj-${key}`}
          data-anj-seal-id={key}
          data-anj-image-size="32"
          data-anj-image-type="basic-small"
        />
      ) : (
        <div>
          <img
            src={`${BASE_IMG_URL}/footer/anguan.png`}
            className={styles.licenseImg}
          />
        </div>
      )}
    </div>
  );
};

export default AnguanLicense;
