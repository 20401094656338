export const CASINO_LIMIT_GAMES = 24;

export const CASINO_INSIDE_CASINO = 'casino-redirect-back';

export const MOBILE_POST_MASSAGE_SIGN_IN = 'pn-wv-data';

export const MOBILE_SET_KEYCHAIN_DATA = 'pn-wv-ssd';

export const MOBILE_GET_KEYCHAIN_DATA = 'pn-wv-gsd';

export const MOBILE_POST_MASSAGE_ANALYTICS = 'pn-wv-an';

export const MOBILE_POST_MASSAGE_USER_LANGUAGE = 'pn-wv-lang';

export const COPY_TO_CLIPBOARD = 'copy-to-clipboard';

export const BASE_URL = `${window.location.origin}/casino`;
