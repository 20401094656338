// @ts-nocheck
import { IS_DEVELOPMENT_MODE } from 'constants/ui';

export const initializingPixel = () => {
  if (!window.pixelTick) {
    window.pixelTick = 0;
  }

  if (!process.env.REACT_APP_PIXEL || IS_DEVELOPMENT_MODE) {
    console.warn('PIXEL KEY NOT FOUND');

    return;
  }

  if (!window.fbq) {
    window.pixelTick += 1;

    setTimeout(() => {
      if (window.pixelTick > 10) {
        return;
      }
      initializingPixel();
    }, 3000);

    return;
  }

  try {
    window.fbq('init', process.env.REACT_APP_PIXEL);
    window.fbq('track', 'PageView');
  } catch (error) {
    console.error('PIXEL initialization ERROR');
  }
};
