import { BASE_URL } from 'constants/transport';

export const TURKISH_HAVALE_HERA_BANK_IDS = [
  {
    id: 1,
    name: 'Garanti Bankası',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/garanti.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 2,
    name: 'Akbank',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/akbank.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 3,
    name: 'DenizBank',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/deniz.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 4,
    name: 'FinansBank',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/finansbank.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 5,
    name: 'İş Bankası',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/isBank.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 6,
    name: 'Ziraat Bankası',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/ziraat.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 7,
    name: 'Türkiye Ekonomik Bankası',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/teb.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 8,
    name: 'Halkbank',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/halkbank.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 9,
    name: 'Albaraka Türk',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/albaraka.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 10,
    name: 'KuveytTürk',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/KuveytTurk.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 11,
    name: 'İş Bankası',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/isBank.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 12,
    name: 'Fibabanka',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/fibababank.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 13,
    name: 'Yapı Kredi',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/yapiKredi.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 14,
    name: 'Vakıfbank',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/vakifBank.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 15,
    name: 'HSBC',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/hsbc.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 16,
    name: 'İng Bank',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/ing.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 17,
    name: 'Şekerbank',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/sekerbank.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 18,
    name: 'DenizBank',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/deniz.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
];
