import { Outlet, useLocation } from 'react-router-dom';
import cln from 'classnames';

import ProfileTabs from 'components/ProfileComponents/ProfileTabs';
import SignIn from 'components/Alerts/Modal/components/SignIn';

import { ERouteNames } from 'Routes/types';

import animation from 'styles/animation.module.css';
import styles from './HOCProtectedRoute.module.css';
import { useHookFabric } from 'hooks/useHookFabric';
import { AuthInfo } from 'react-memory-optimization/dist/lib/store/user/types';
import { useAuth } from 'react-render-hooks';

const HOCProtectedRoute = () => {
  const isAuthInfo = useHookFabric<AuthInfo>({
    fn: useAuth,
  });

  const { pathname } = useLocation();

  if (!isAuthInfo?.isAuth) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SignIn />
      </div>
    );
  }

  return (
    <div className={cln(animation.animationFadeIn, styles.wrapper)}>
      {pathname !== ERouteNames.PromotionMonthly &&
        pathname !== ERouteNames.PromoCode && <ProfileTabs />}

      <Outlet />
    </div>
  );
};

export default HOCProtectedRoute;
