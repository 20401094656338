import { useWeeklyBonus } from 'react-render-hooks';
import { ReturnTypeWeeklyBonus } from 'react-memory-optimization/dist/lib/store/operations/entities/vipClub/types';

import BonusCardComponentKit from 'components/ui/KitComponents/BonusCardComponentKit';

import { useZustandWebConfiguration } from 'stores/configuration/store';
import { getBonusCardsSelector } from 'stores/configuration/selectors';

import { getNextSaturday } from 'utils/date';
import { useHookFabric } from 'hooks/useHookFabric';

const WeeklyCard = () => {
  const bonusImagePaths = useZustandWebConfiguration(getBonusCardsSelector);

  const weekly = useHookFabric<ReturnTypeWeeklyBonus>({
    fn: useWeeklyBonus,
  });

  console.log('🚀 ~ WeeklyCard ~ weekly:', weekly);

  return (
    <BonusCardComponentKit
      title="newBonuses.cards.weekly"
      description="newBonuses.cards.weeklyDescription"
      id={weekly?.id || -1}
      value={`${weekly?.amount || ''}`}
      endTimestamp={weekly?.endData || getNextSaturday()}
      bonusImagePath={bonusImagePaths?.weekly || ''}
    />
  );
};

export default WeeklyCard;
