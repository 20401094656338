import {
  useWeeklyBonus,
  useRakeBack,
  useBonuses,
  useAuth,
} from 'react-render-hooks';
import {
  ReturnTypeRakeBake,
  ReturnTypeWeeklyBonus,
} from 'react-memory-optimization/dist/lib/store/operations/entities/vipClub/types';

import { isAuthorizedUserSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';

import { ModalTypes } from 'stores/modal/types';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './BonusButton.module.css';
import { BonusesResponse } from 'react-memory-optimization/dist/lib/store/operations/types';
import { AuthInfo } from 'react-memory-optimization/dist/lib/store/user/types';
import { BASE_URL } from 'constants/transport';

const BonusButton = () => {
  const setModal = useZustandModalStore(setModalInfoAction);

  const isAuth = useHookFabric<AuthInfo>({
    fn: useAuth,
  });

  const { bonusOnDeposit = [], freeSpins = [] } =
    useHookFabric<BonusesResponse>({
      fn: useBonuses,
      customProps: {
        isActive: false,
      },
    }) || {};

  const weekly = useHookFabric<ReturnTypeWeeklyBonus>({
    fn: useWeeklyBonus,
  });

  const rakebackInfo = useHookFabric<ReturnTypeRakeBake>({
    fn: useRakeBack,
  });

  const openModal = (t: ModalTypes) => () => {
    setModal(t);
  };
  const count =
    Number(Boolean(rakebackInfo?.rakeback)) +
    Number(Boolean(weekly?.amount)) +
    bonusOnDeposit.length +
    freeSpins.length;

  // handleClick={openModal(ModalTypes.Bonuses)}

  if (!isAuth) {
    return null;
  }

  return (
    <div
      onClick={openModal(ModalTypes.Bonuses)}
      className={styles.wrapperBonusButton}
    >
      <img src={`${BASE_URL}/icons-elon/bonuses/treasure.webp`} />
      {!!count && <div className={styles.count}>{count}</div>}
    </div>
  );
};

export default BonusButton;
