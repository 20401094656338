import { APP_TYPE, IS_DEVELOPMENT_MODE, IS_WEBP } from 'constants/ui';
import { getCasinoCategoryPath } from './utils';
import { BASE_URL } from 'constants/transport';

const BETTING_PATH_NAME =
  process.env.NODE_ENV === 'production'
    ? '/Scripts/brospis[language]'
    : `${BASE_URL}/mock/betting/brospis[language].uu`;

const CASINO_CATEGORY_PATH = getCasinoCategoryPath();

export const PROVIDER_BASE_IMG_PATH = IS_DEVELOPMENT_MODE
  ? 'https://elonw.com'
  : '';

export const SETTINGS = {
  appType: APP_TYPE,
  authFrameUrl: 'https://googlemetrics.com/eljk3jd8j3kl98kl37jsnk3k/',
  referralBaseUrl: 'https://data.azufront.com/ref_service', // TODO MOVE TO CONFIG
  isDevMode: IS_DEVELOPMENT_MODE,
  casinoSettings: {
    casinoCategoryUrls: CASINO_CATEGORY_PATH,
    isVertical: true, //IS_VERTICAL && IS_DEVELOPMENT_MODE
    customPath: 'vertical_2/',
    webp: IS_WEBP,
    tournaments: {
      path: IS_DEVELOPMENT_MODE
        ? `https://elonw.com/test-tournaments/brands/${APP_TYPE}/tournaments.json`
        : '/Scripts/provider_tournaments.json',
      inHouseBattlePaths: {
        mobileActive: `${BASE_URL}/icons-elon/tournaments/${APP_TYPE}/mobile_background.png`,
        desktopActive: `${BASE_URL}/icons-elon/tournaments/${APP_TYPE}/battles-ny.png`,
        mobileInactive: `${BASE_URL}/icons-elon/tournaments/${APP_TYPE}/finished_mobile.png`,
        desktopInactive: `${BASE_URL}/icons-elon/tournaments/${APP_TYPE}/finished.png`,
      },
      providerBasePath: PROVIDER_BASE_IMG_PATH,
    },
  },
  bettingSettings: {
    pathname: BETTING_PATH_NAME,
  },
};
