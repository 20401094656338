import { BASE_URL } from 'constants/transport';
import React, { useEffect, useState } from 'react';
import { adaptedBonus } from 'services/adapters';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

export type PackName = 'basic' | 'bronze' | 'silver' | 'gold';

type BonusPackName = 'freespin' | 'deposit';

type BonusPackSettings = {
  id_currency: number;
  max_bet: number;
  max_bonus: number;
  min_bet: number;
  min_dep: number;
  spin_bet: number;
};

export type BonusPack = {
  game: number;
  pack_id: number;
  percent_or_freespins: number;
  settings: BonusPackSettings[];
  type: BonusPackName;
  wager: number;
};

export type AdaptedBonusInfo = {
  gameId: number;
  packId: number;
  bonusOffer: number;
  bonusInfo: BonusPackSettings;
  type: BonusPackName;
  wager: number;
};
export type AdaptedPack = {
  packName: PackName;
  bonuses: AdaptedBonusInfo[];
};

type Packs = {
  pack: PackName;
  bonuses: BonusPack[];
};

const useBonusPacks = () => {
  const [packs, setPacks] = useState<AdaptedPack[] | null>(null);

  const userCurrencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const adaptedValue = (data: Packs[], userCurrencyId: number) => {
    const [_, ...rest] = data;

    const packData: AdaptedPack[] = [];

    rest.forEach((pack) => {
      const packName = pack.pack;

      const bonuses: AdaptedBonusInfo[] = [];

      pack.bonuses.forEach((b) => {
        const bonus = adaptedBonus(b, userCurrencyId);

        if (!bonus) {
          return true;
        }

        bonuses.push(bonus);
      });

      packData.push({
        packName,
        bonuses,
      });
    });

    setPacks(packData);
  };

  const getPacks = async (userCurrencyId: number) => {
    try {
      const pathname =
        process.env.NODE_ENV !== 'production'
          ? 'mock/bonusPacks.json'
          : 'Scripts/casino_bonus_description.json';

      const response = await fetch(`${BASE_URL}/${pathname}`, {
        cache: 'no-cache',
      });

      const { list_bonuses } = (await response.json()) as {
        list_bonuses: Packs[];
      };

      adaptedValue(list_bonuses, userCurrencyId);
    } catch (error) {
      console.log('🚀 ~  error:', error);
    }
  };

  useEffect(() => {
    if (!userCurrencyId) {
      return;
    }
    getPacks(userCurrencyId);
  }, [userCurrencyId]);

  return packs;
};

export default useBonusPacks;
