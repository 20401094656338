import { useEffect, useState } from 'react';
import { ModalLayout, Text, useTranslate } from 'react-gldn-kit';
import cln from 'classnames';
import { useLocation } from 'react-router';
import { useCountryInfo } from 'react-render-hooks';
import { CountryInfo } from 'react-memory-optimization/dist/lib/store/user/types';

import {
  getActiveRegistrationFormSelector,
  getRegistrationTabsSelector,
  setActiveRegistrationFormAction,
} from 'stores/forms/signUp/selectors';
import { useZustandRegistrationStore } from 'stores/forms/signUp/store';
import { ERegistrationForm } from 'stores/forms/signUp/types';

import ComponentKitTabs from 'components/ui/KitComponents/Tab';
import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';
import OneClickKit from './components/Forms/OneClickKit';
import Email from './components/Forms/Email';
import Phone from './components/Forms/Phone';

import { useZustandModalStore } from 'stores/modal/store';
import {
  closeAndOpenModalAction,
  getModalAdditionalInfoSelector,
} from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './SignUp.module.css';

type Props = {
  classes?: string;
  isWithBonusText?: boolean;
  isHideHeader?: boolean;
  promoCodeClasses?: string;
};

const SignUp = (props: Props) => {
  const {
    classes,
    isWithBonusText = false,
    isHideHeader = true,
    promoCodeClasses,
  } = props;

  const activeForm = useZustandRegistrationStore(
    getActiveRegistrationFormSelector
  );

  const setRegistrationType = useZustandRegistrationStore(
    setActiveRegistrationFormAction
  );

  const countryInfo = useHookFabric<CountryInfo>({
    fn: useCountryInfo,
  });

  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const { getText } = useTranslate('', '');

  const tabs = useZustandRegistrationStore(getRegistrationTabsSelector);

  const { pathname } = useLocation();

  const data = useZustandModalStore(getModalAdditionalInfoSelector) || {};

  const [actualTabs, setActualTabs] = useState<
    {
      label: string;
      value: ERegistrationForm;
    }[]
  >([]);

  const { bonusSignUp = false, bonusText } = data;

  const openSignIn = () => {
    setModal(ModalTypes.SignIn);
  };

  const onClose = () => {
    setModal(ModalTypes.Unknown);
  };

  useEffect(() => {
    if (tabs.length && activeForm === null) {
      setRegistrationType(tabs[0].value);
    }
  }, [activeForm, tabs]);

  useEffect(() => {
    if (!countryInfo) {
      return;
    }
    let baseTabs = tabs;

    // TODO TURKEY ( remove reg by Phone )
    if (countryInfo?.countryId === 792) {
      baseTabs = baseTabs.filter((t) => t.value !== ERegistrationForm.Phone);
    }

    setActualTabs(baseTabs);
  }, [countryInfo, pathname, tabs]);

  return (
    <ModalLayout
      classes={cln(styles.wrapperReferralFailure, classes)}
      popUpHeaderProps={
        !bonusSignUp && !isWithBonusText
          ? {
              transparent: true,
              title: getText('registrations.title'),
            }
          : undefined
      }
      buttonClose={!bonusSignUp && !isWithBonusText ? onClose : undefined}
    >
      <div className={styles.wrapperSignUpModal}>
        {(bonusSignUp || !isHideHeader) && (
          <div className={styles.bonusText}>
            <div className={styles.textTitle}>
              <Text idT="wheelTwo.secondPopUp.title" />
            </div>

            <div className={styles.subText}>
              <Text idT="wheelTwo.secondPopUp.subTitle" />
            </div>

            <Text idT={bonusText ? bonusText : 'wheelTwo.secondPopUp.bonus'} />
          </div>
        )}

        <div className={styles.wrapperTabsAndForm}>
          {activeForm !== null && actualTabs.length !== 1 && (
            <ComponentKitTabs
              activeValue={activeForm}
              data={actualTabs}
              onChange={setRegistrationType}
              classes={styles.tabs}
              type="underline"
            />
          )}

          <div className={styles.zIndex}>
            {activeForm === ERegistrationForm.Email && (
              <Email isSelected promoCodeClasses={promoCodeClasses} />
            )}

            {activeForm === ERegistrationForm.Phone && <Phone isSelected />}
            {activeForm === ERegistrationForm.PhoneWithoutCode && (
              <Phone isSelected withoutCode />
            )}

            {activeForm === ERegistrationForm.OneClick && (
              <OneClickKit isSelected />
            )}
          </div>
        </div>

        <ComponentKitBaseButton
          size="--button-full"
          color="dark"
          buttonHeight="--button-height-full"
          text="header.login"
          classes={styles.authButton}
          handleClick={openSignIn}
          centreText
        />
      </div>
    </ModalLayout>
  );
};

export default SignUp;
