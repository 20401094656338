import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useAuth } from 'react-render-hooks';

import { PAGES_WITHOUT_NAVIGATION_BAR } from './constants';

import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { setFooterTypeAction, setHeaderTypeAction } from 'stores/ui/selectors';
import { useZustandUiStore } from 'stores/ui/store';

import { EFooterType, EHeaderType } from 'stores/ui/types';
import { useHookFabric } from 'hooks/useHookFabric';
import { AuthInfo } from 'react-memory-optimization/dist/lib/store/user/types';

type Props = {
  children: React.ReactElement;
};

export const HOCUiTypes = ({ children }: Props) => {
  const setHeaderType = useZustandUiStore(setHeaderTypeAction);

  const setFooterType = useZustandUiStore(setFooterTypeAction);

  const isAuth = useHookFabric<AuthInfo>({
    fn: useAuth,
  });

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes('landing') && !isAuth) {
      setHeaderType(EHeaderType.Hidden);
      setFooterType(EFooterType.Hidden);

      return;
    }

    const footerType = PAGES_WITHOUT_NAVIGATION_BAR.includes(pathname)
      ? EFooterType.Hidden
      : EFooterType.Base;

    setFooterType(footerType);

    if (isAuth) {
      setHeaderType(EHeaderType.Authorized);

      return;
    }

    setHeaderType(EHeaderType.SignUp);
  }, [pathname, isAuth]);

  return children;
};
