import { setMainLoaderPercentAction } from 'stores/mainLoader/selectors';
import { useZustandMainLoaderStore } from 'stores/mainLoader/store';
import { create } from 'zustand';
import { WebConfiguration, ZustandConfigurationStore } from './types';
import { useZustandRegistrationStore } from 'stores/forms/signUp/store';
import { getFromLocalStorage } from 'utils/localstorage';
import { CUSTOM_SOCKET_NAME } from 'constants/localstoreKeys';
import { APP_TYPE, CUSTOM_THEME_NAME, IS_DEVELOPMENT_MODE } from 'constants/ui';
import { BASE_URL } from 'constants/transport';

export const useZustandWebConfiguration = create<ZustandConfigurationStore>(
  (set) => ({
    webConfiguration: null,
    colors: [],

    getWebConfiguration: async () => {
      const setPercent = setMainLoaderPercentAction(
        useZustandMainLoaderStore.getState()
      );

      try {
        const responseColors = await fetch(
          `${BASE_URL}/configs/themes/${APP_TYPE}/variables_${
            CUSTOM_THEME_NAME || 'dark'
          }.json`,
          {
            method: 'GET',
            cache: 'no-cache',
          }
        );

        const { colors } = (await responseColors.json()) as {
          colors: { key: string; value: string }[];
        };

        set({ colors });

        const root = document.querySelector<HTMLElement>(':root');

        if (!root) {
          throw new Error('No root element');
        }
        colors.forEach((color) => {
          root.style.setProperty(color.key, color.value);
        });

        // TODO resize screen change variables
        const vw = Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        );

        const vh = Math.max(
          document.documentElement.clientHeight || 0,
          window.innerHeight || 0
        );

        root.style.setProperty('--app-height', `${window.innerHeight}px`);
        root.style.setProperty('--app-width', `${window.innerWidth}px`);
        root.style.setProperty('--vw', `${vw}px`);
        root.style.setProperty('--vh', `${vh}px`);

        setPercent(25);

        const responseWeb = await fetch(
          `${BASE_URL}/configs/themes/${APP_TYPE}/web${
            !IS_DEVELOPMENT_MODE ? '' : '_dev'
          }.json`,
          {
            method: 'GET',
            cache: 'no-cache',
          }
        );

        const webConfiguration =
          (await responseWeb.json()) satisfies WebConfiguration;

        useZustandRegistrationStore
          .getState()
          .setAvailableRegistrationTypes(webConfiguration.registration.forms);

        if (!webConfiguration.transport.address) {
          // TODO socket by DOMAIN HERE
          webConfiguration.transport.address = `wss://wss.${window.location.host}/casino`;
        }
        const customSocketAddress = getFromLocalStorage(CUSTOM_SOCKET_NAME);

        if (customSocketAddress) {
          webConfiguration.transport.address = customSocketAddress;
        }

        set({ webConfiguration });

        setPercent(50);
      } catch (error) {
        console.error(error, 'CRITICAL ERROR');
      }
    },
  })
);
