import cln from 'classnames';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'react-render-hooks';
import { AuthInfo } from 'react-memory-optimization/dist/lib/store/user/types';
import { useHookFabric } from 'hooks/useHookFabric';
import { ERouteNames } from 'Routes/types';
import JivoChat from 'components/JivoChat';
import { Text } from 'react-gldn-kit';

import { BETTING_NAVIGATION_BAR, CASINO_NAVIGATION_BAR } from './constants';

import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';

import { ModalTypes } from 'stores/modal/types';
import { useZustandWebConfiguration } from 'stores/configuration/store';
import {
  isAvailableBettingSelector,
  isFrameBettingSelector,
} from 'stores/configuration/selectors';
import { useZustandBettingFrameStore } from 'stores/betting/frame/store';
import { setBettingFramePropertyAction } from 'stores/betting/frame/selectors';
import { useZustandUiStore } from 'stores/ui/store';
import { getFooterTypeSelector } from 'stores/ui/selectors';
import { EFooterType } from 'stores/ui/types';

import styles from './NavigationBar.module.css';
import { APP_TYPE, IS_DEVELOPMENT_MODE } from 'constants/ui';
import { EAppType } from 'react-gldn-kit/dist/lib/types/ui';

const NavigationBar = () => {
  const { pathname = '' } = useLocation();

  const setModal = useZustandModalStore(setModalInfoAction);

  const navigate = useNavigate();

  const setFrameProperty = useZustandBettingFrameStore(
    setBettingFramePropertyAction
  );

  const footerType = useZustandUiStore(getFooterTypeSelector);

  const isBettingFrame = useZustandWebConfiguration(isFrameBettingSelector);
  const isBettingAvailable = useZustandWebConfiguration(
    isAvailableBettingSelector
  );

  const isAuth = useHookFabric<AuthInfo>({
    fn: useAuth,
  });

  const isBetting = pathname.includes(ERouteNames.BettingHome);

  const isBettingFramePage = isBettingFrame && isBetting;

  const handleOpenModal = (type: ModalTypes) => () => {
    // TODO added this logic because mixer for transaction can't works with two or more dependencies
    // mb later
    if (pathname === ERouteNames.ProfileTransactions) {
      navigate(ERouteNames.ProfileAccount);
    }

    setModal(type);
  };

  const handleNavigateBettingFrame =
    (
      path: ERouteNames,
      modalType: ModalTypes,
      isPopUp: boolean,
      isAuthDepends: boolean
    ) =>
    () => {
      if (isAuthDepends) {
        setModal(ModalTypes.SignIn);

        return;
      }

      if (isPopUp) {
        // setFrameProperty('openPopUpType', modalType);
        setModal(modalType);

        return;
      }

      if (path === ERouteNames.CasinoHome) {
        navigate(path);

        return;
      }

      setFrameProperty('navigateToPage', path);
    };

  // const prodButtons =
  //   isBetting && APP_TYPE !== EAppType.Elon
  //     ? BETTING_NAVIGATION_BAR
  //     : CASINO_NAVIGATION_BAR;

  // const buttons = IS_DEVELOPMENT_MODE ? CASINO_NAVIGATION_BAR : prodButtons;
  const buttons = CASINO_NAVIGATION_BAR;

  return (
    <div
      className={cln(styles.wrapperNavigationBar, {
        [styles.hide]: footerType === EFooterType.Hidden,
      })}
    >
      <nav className={styles.navigation}>
        {buttons.map((button) => {
          const {
            isPopUp,
            text,
            icon,
            modalType = ModalTypes.SignIn,
            isAuthDependes = false,
            path = ERouteNames.Home,
            isSupport,
            isBetting = false,
          } = button;

          if (isBetting && !isBettingAvailable) {
            return null;
          }

          if (isSupport) {
            return <JivoChat key={text} />;
          }

          const isAuthDepends = isAuthDependes && !isAuth;

          if (isPopUp || isAuthDepends || isBettingFramePage) {
            return (
              <div
                key={text}
                className={cln(
                  styles.iconButtonContainer,
                  styles.navigationButton
                )}
                onClick={
                  isBettingFramePage
                    ? handleNavigateBettingFrame(
                        path,
                        modalType,
                        Boolean(isPopUp),
                        Boolean(isAuthDepends)
                      )
                    : handleOpenModal(
                        isAuthDepends ? ModalTypes.SignIn : modalType
                      )
                }
              >
                {icon}
                <div className={styles.text}>
                  <Text idT={text} />
                </div>
              </div>
            );
          }

          return (
            <NavLink
              key={text}
              to={path}
              className={({ isActive }) =>
                cln(styles.navigationButton, {
                  [styles.activeNav]: isActive,
                })
              }
            >
              {icon}

              <div className={styles.text}>
                <Text idT={text} />
              </div>
            </NavLink>
          );
        })}
      </nav>
    </div>
  );
};

export default NavigationBar;
