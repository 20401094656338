import { BASE_URL } from 'constants/transport';
import { BankIdInfo, BankIds, TurkishBanks } from './types';

export const TURKISH_BANKS: TurkishBanks[] = [
  {
    id: 2,
    name: 'Akbank',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/akbank.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 3,
    name: 'DenizBank',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/deniz.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 4,
    name: 'FinansBank',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/finansBank.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 15,
    name: 'ING Bank',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/ing.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 13,
    name: 'Ziraat Bankası',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/ziraat.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 6,
    name: 'İş Bankası',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/IsBank.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 7,
    name: 'TEB',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/teb.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 8,
    name: 'Vakıfbank',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/vakifBank.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 1,
    name: 'Garanti BBVA',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/garanti.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 11,
    name: 'Yapı Kredi',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/yapiKredi.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 19,
    name: 'KuveytTürk',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/KuveytTurk.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 32,
    name: 'Enpara',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/enpara.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 25,
    name: 'Turkiye Finans Katilim',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/turkiyeFinanse.svg`,
    minLimit: 0,
    maxLimit: 0,
  },
];

export const TURKISH_IBAN_MASK = 'TRXX XXXX XXXX XXXX XXXX XXXX XX';

export const BANK_IDS: BankIdInfo[] = [
  {
    id: BankIds.FastEft,
    name: 'FAST EFT',
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/fastEft.svg`,
    rank: 2,
    minLimit: '100.00',
    maxLimit: '9999999.00',
  },
  {
    id: BankIds.GarantiBank,
    name: 'Garanti Bankası',
    rank: 1,
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/garanti.svg`,
    minLimit: '100.00',
    maxLimit: '150000.00',
  },
  {
    id: BankIds.Kuveyt,
    name: 'Kuveyt Türk',
    rank: 1,
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/KuveytTurk.svg`,
    minLimit: '100.00',
    maxLimit: '9999999.00',
  },
  {
    id: BankIds.Fibabank,
    name: 'Fibabank',
    rank: 1,
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/fibababank.svg`,
    minLimit: '100.00',
    maxLimit: '150000.00',
  },
  {
    id: BankIds.Albaraka,
    name: 'Albaraka Türk',
    rank: 1,
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/albaraka.svg`,
    minLimit: '100.00',
    maxLimit: '100000.00',
  },
  {
    id: BankIds.Finansbank,
    name: 'Finansbank / Enpara',
    rank: 1,
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/finansBank.svg`,
    minLimit: '100.00',
    maxLimit: '9999999.00',
  },
  {
    id: BankIds.Denizbank,
    name: 'Denizbank',
    rank: 1,
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/deniz.svg`,
    minLimit: '100.00',
    maxLimit: '9999999.00',
  },
  {
    id: BankIds.Akbank,
    name: 'Akbank',
    rank: 1,
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/akbank.svg`,
    minLimit: '100.00',
    maxLimit: '9999999.00',
  },
  {
    id: BankIds.Halkbank,
    name: 'Halkbank',
    rank: 1,
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/halkbank.svg`,
    minLimit: '100.00',
    maxLimit: '999999.00',
  },
  {
    id: BankIds.Odea,
    name: 'Odea Bank',
    rank: 1,
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/odea.svg`,
    minLimit: '100.00',
    maxLimit: '100000.00',
  },
  {
    id: BankIds.Ziraat,
    name: 'Ziraat Bankası',
    rank: 1,
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/ziraat.svg`,
    minLimit: '100.00',
    maxLimit: '9999999.00',
  },
  {
    id: BankIds.Vakifbank,
    name: 'Vakıfbank',
    rank: 1,
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/vakifBank.svg`,
    minLimit: '100.00',
    maxLimit: '1000000.00',
  },
  {
    id: BankIds.NKolay,
    name: 'NKolay Bank',
    rank: 1,
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/nKolayBank.svg`,
    minLimit: '100.00',
    maxLimit: '50000.00',
  },
  {
    id: BankIds.Sekerbank,
    name: 'Şekerbank',
    rank: 1,
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/sekerbank.svg`,
    minLimit: '100.00',
    maxLimit: '100000.00',
  },
  {
    id: BankIds.TEB,
    name: 'TEB',
    rank: 1,
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/teb.svg`,
    minLimit: '100.00',
    maxLimit: '100000.00',
  },
  {
    id: BankIds.TurkiyeFinans,
    name: 'TurkiyeFinans',
    rank: 1,
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/finansBank.svg`,
    minLimit: '100.00',
    maxLimit: '999999.00',
  },
  {
    id: BankIds.Yapi,
    name: 'Yapı Kredi',
    rank: 1,
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/yapiKredi.svg`,
    minLimit: '100.00',
    maxLimit: '150000.00',
  },
  {
    id: BankIds.Vakif,
    name: 'Vakıf Katılım',
    rank: 1,
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/vakifBank.svg`,
    minLimit: '100.00',
    maxLimit: '100000.00',
  },
  {
    id: BankIds.ZiraatKatilim,
    name: 'Ziraat Katılım',
    rank: 1,
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/ziraat.svg`,
    minLimit: '100.00',
    maxLimit: '100000.00',
  },
  {
    id: BankIds.IsBankasu,
    name: 'İş Bankası',
    rank: 1,
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/IsBank.svg`,
    minLimit: '100.00',
    maxLimit: '100000.00',
  },
  {
    id: BankIds.ING,
    name: 'ING Bank',
    rank: 1,
    src: `${BASE_URL}/icons-elonpayments/banks/turkish/ing.svg`,
    minLimit: '100.00',
    maxLimit: '999999.00',
  },
];
