import { ETicketStatus } from 'react-memory-optimization/dist/lib/store/betting/entities/tickets/types';
import React from 'react';
import { BASE_URL } from 'constants/transport';

type Props = {
  status: ETicketStatus;

  isWin: boolean;
};

const MatchStatus = ({ status, isWin }: Props) => (
  <div>
    {status === ETicketStatus.Settled ? (
      <img
        src={`${BASE_URL}/icons-elon/betting/${!isWin ? 'lose' : 'win'}.svg`}
      />
    ) : (
      <img src={`${BASE_URL}/icons-elon/betting/time.svg`} />
    )}
  </div>
);

export default MatchStatus;
