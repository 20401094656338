import { BASE_URL } from 'constants/transport';
import { APP_TYPE } from 'constants/ui';
import { EAppType } from 'react-gldn-kit/dist/lib/types/ui';

export const getCasinoCategoryPath = () => {
  const IS_PROD = process.env.NODE_ENV === 'production';

  if (!IS_PROD) {
    return [
      `${BASE_URL}/mock/casino/bcasconf_2.uu`,
      `${BASE_URL}/mock/casino/bcasconf_1.uu`,
    ];
  }

  switch (APP_TYPE) {
    case EAppType.Elon:
      return ['/Scripts/bcasconf_2', '/Scripts/bcasconf_1'];
    case EAppType.Azure:
      return ['', ''];
    case EAppType.FsWin:
      return ['/Scripts/bcasconf_2', '/Scripts/bcasconf_1'];
    case EAppType.Opera:
      return ['/Scripts/bcasconf_2_opera', '/Scripts/bcasconf_1_opera'];
    case EAppType.JabiBet:
      return ['/Scripts/bcasconf_2', '/Scripts/bcasconf_1'];
    case EAppType.XXX:
      return ['/Scripts/bcasconf_2', '/Scripts/bcasconf_1'];
    case EAppType.M1:
      return ['/Scripts/bcasconf_2_m1', '/Scripts/bcasconf_1_m1'];

    default:
      break;
  }
};
