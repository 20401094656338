import React from 'react';
import { getLicenseInfosSelector } from 'stores/configuration/selectors';
import { useZustandWebConfiguration } from 'stores/configuration/store';

import styles from './CuracauLicense.module.css';
import { BASE_URL } from 'constants/transport';

const CuracauLicense = () => {
  const licenseInfo = useZustandWebConfiguration(getLicenseInfosSelector);

  const isCurrentDomainLicensed = licenseInfo?.domains?.includes(
    window.location.host
  );

  const licenseLink = isCurrentDomainLicensed
    ? licenseInfo?.validatorLinks[0]
    : undefined;

  if (!isCurrentDomainLicensed) {
    return (
      <img
        src={`${BASE_URL}/icons-elon/footer/verifiedLicence.png`}
        className={styles.licenseImg}
        alt=""
      />
    );
  }

  return (
    <a
      href={licenseLink}
      target="_blank"
      referrerPolicy="origin"
      rel="noopener noreferrer"
      className={styles.link}
    >
      <iframe src={licenseInfo?.validatorSite} width="150" />
    </a>
  );
};

export default CuracauLicense;
